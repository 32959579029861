import { mapState } from 'vuex';
import { myRecordList } from '@/service/luckDraw/index'

export default {
    name: 'award-list',
    props: {
        show: {
            type: Boolean, // 是否显示
            default: false,
        }
    },
    data(){
        return{
            thead: [ '奖品名称', '商品名称', '收货地点', '奖品状态' ],
            awardState: {
                0: '待领奖',
                1: '待审核',
                2: '已审核',
                3: '已拒绝'
            },
            awardList: [],
            awardListPage: [],
            curPage: 1, //当前页码
        }
    },
    computed:{
        ...mapState({
            userId: state => state.user.user.id,
        })
    },
    mounted() {

    },
    watch: {
        show(val){
            if(val) this.init()
       }
    },
    methods: {
        init(){
            this.getRecordList()
            this.curPage = 1
        },
        // 关闭回调
        onClose(){
            this.$emit('close')
        },
        // 改变页码
        onChnagePage(type){
            let { curPage, awardList } = this;

            if(type == 'per' && curPage > 1) this.curPage = curPage - 1
            if(type == 'next' && awardList.length > curPage * 7) this.curPage = curPage + 1
            this.awardListPage = awardList.slice((this.curPage - 1)*7, this.curPage*7)
        },
        // 获取我的获奖记录
        async getRecordList(){
            let { userId } = this;
            const res = await myRecordList({ activityId: 108, userId })
            this.awardList = res
            this.awardListPage = res.slice(0, 7)
        },
        // 点击奖品领取按钮
        onAwardBtn(row){
            if(row.state !== 0) return
            this.onClose()
            this.$emit('confirm', { awardObj: {
                id: row.awardId,
                name: row.awardName,
                recordid: row.id
            } })
        }
    }
}
